import React from "react"
import Layout from "../../layouts/Layout"
import { Header } from "../../components/Header"
import { Grids } from "../../components/Grids"
import { useSitePortfolioEs } from "../../hooks/use-site-portfolio-es"

const IndexPage = () => {
  const edges = useSitePortfolioEs()

  const metadata = {
    title: "Nicolás Díaz · Programador Frontend & Diseñador UI",
    description:
      "Soy Desarrollador Frontend y Diseñador de UI. Realmente soy un apasionado del diseño web. Tengo un alto conocimiento y experiencia en diferentes campos como el diseño de frontend, desarrollo, prototipos y diseño de interfaces. Administro un conjunto de tecnologías especializadas como javascript, nodejs, npm, React, Nest.js y otras.",
    cover: "/images/covers/cover-nicolascodes.png",
    lang: "es",
    type: "website",
  }

  return (
    <Layout metadata={metadata}>
      <Header
        title="Nicolás"
        content="Soy Frontend Developer & UI Designer. Me apasiona mucho el diseño web. Tengo un alto conocimiento y experiencia en diferentes campos como diseño de frontend, desarrollo, prototipos y diseño de interfaces. Manejo un conjunto de tecnologías especializadas como javascript, nodejs, npm, React, Nest.js y otros."
      />
      <Grids edges={edges} />
    </Layout>
  )
}
export default IndexPage
